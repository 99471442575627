html, body {
    height: 100%;
    margin: 0;
}

.signin-main {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}